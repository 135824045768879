@import 'constants.scss';

.associated-toe {
  display: flex;
  background-color: $AthensGray;
  flex-direction: column;
  align-items: flex-start;

  .ctars-container {
    position: relative;
    min-height: 100px;
    .ctar-item {
      height: 50px;

      &:not(.inactive) {
        td.v3-td {
          font-weight: 600;
        }
      }

      &.inactive {
        td.v3-td {
          opacity: 0.5;
        }
        td.v3-td.button {
          opacity: 1;
        }
      }

      .active {
        font-weight: bold;
        color: $JewelGreen;
        &.is_deleted {
          font-weight: normal;
          color: $BrightRed;
        }
      }
      
      button.v3-button {
        font-weight: 600;
        height: 35px;
        width: 80px;
        &:not(:hover) {
          color: $Silver;
          border-color: $Silver;
        }
      }
    }
  }

}

.no-toe {
  margin: 0 0 20px 0;
}