@import 'constants.scss';

div.v3-text-input {
  position: relative;
  border-bottom: 1px solid $GeyserGray;
  margin-bottom: 30px;

  &.full-border {
    border-bottom: none;

    label {
      padding: 8px 0;
      font-weight: 600;
    }

    input {
      padding: 13px;
      border: 1px solid $GeyserGray;
      border-radius: 4px;

      &:focus {
        border-color: $JewelGreen;
      }
    }
  }

  label {
    display: block;
    font-size: 12px;
    &.disabled {
      color: $Silver;
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0;
    border: none;
    font-size: $FontSize;
    color: $FontColor;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $Silver;
    }

    &:disabled {
      background-color: #fff;
    }
  }

  .focus-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: $JewelGreen;
    transition: 0.2s;
  }

  input:focus ~ .focus-indicator {
    width: 100%;
  }

  &.error > .focus-indicator {
    width: 100%;
    background: $BrightRed;
  }

  &.error > input,
  &.error > input:focus {
    border: 1px solid $BrightRed;
  }

  &.error > .error-text {
    font-size: 10px;
    color: $BrightRed;
    position: absolute;
    bottom: -20px;
    right: 0;
  }

  .icon-wrapper {
    position: absolute;
    bottom: 2px;
    right: 8px;
    color: $FontColor;
    border-color: $FontColor;
    fill: $FontColor;
  }

  .text-link-wrapper {
    position: absolute;
    bottom: 8px;
    right: 0;
    font-size: 10px;
  }
}
