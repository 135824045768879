#login-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0 0 10px;
    font-size: 24px;
  }

  .v3-text-input input {
    width: 300px;
  }
}