.active-licenses-report-container {
  // margin-left: 20px;

  label {
    font-size: smaller;
  }

  .end-date-container {
    margin-top: 10px;
  }

  .get-report-button {
    margin-top: 20px;
  }
}