.search-results-container {
  position: relative;
  
  tbody.toe-table-body {
    .v3-tr {
      height: 50px;
      
      button.v3-button {
        height: 35px;
        width: 80px;
        white-space: nowrap;
      }
    }
  }
}