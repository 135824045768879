@import 'constants.scss';

tr.v3-tr {
  width: 100%;

  &:last-child > td.v3-td {
    border-bottom: 1px solid $GeyserGray;
  }

  &.no-border td.v3-td {
    border: none;
  }
}

.v3-tbody {
  tr.v3-tr.hoverable {
    background-color: $White;
    cursor: pointer;
    td {
      border-top: 1px solid $GeyserGray;
      border-bottom: 1px solid transparent;
      &:first-child { border-left: 1px solid transparent; padding-left: 20px; }
      &:last-child { border-right: 1px solid transparent; padding-right: 20px; }
      & div::after,
      &::after {
        display: block;
        content: attr(data-value);
        font-weight: 600;
        height: 0px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      } // this is a little secret sauce to prevent the tds from resizing on hover due to font-weight change. https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size
    }
    &:hover {
      border-radius: 4px;
      td {
        border-top: 1px solid $GeyserGray;
        border-bottom: 1px solid $GeyserGray;
        // color: $NileBlue;
        font-weight: 600;
      }
      td:first-child {
        border-left: 1px solid $GeyserGray;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      td:last-child {
        border-right: 1px solid $GeyserGray;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      box-sizing: border-box;
      box-shadow: $LargeBoxShadow;
      transform: scale(1); //fixes dropshadow layering issue see https://stackoverflow.com/questions/10874985/box-shadow-on-table-row-not-appearing-on-certain-browsers
      & + tr.v3-tr.hoverable {
        td { border-top: 1px solid transparent; }
      }
    }

    &:last-child td.v3-td {
      border-bottom: 1px solid $GeyserGray;
    }
  }

  tr.v3-tr.accordion-parent {
    cursor: pointer;
    &.expanded {
      background-image: linear-gradient(90deg, rgba(249,250,251,0) 0%, rgba(249,250,251,1) 3%, rgba(249,250,251,1) 97%, rgba(249,250,251,0) 100%);
    }
    &.hoverable.expanded, &.hoverable.expanded:hover {
      box-shadow: none;

      .v3-td {
        font-weight: 600;
        border-bottom: none;

        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
    }

    &:nth-last-child(2) {
      .v3-td {
        border-bottom: 1px solid $GeyserGray;
      }
      &.expanded {
        .v3-td {
          border-bottom: none;
        }
      }
    }

    &.v3-tr.hoverable:not(.expanded):hover { // need the second immediate sibling because first immediate is the accordion row
      & + .v3-tr {
        & + .v3-tr {
          td { border-top: 1px solid transparent; }
        }
      }
    }
  }

  tr.v3-tr.accordion-child {
    // opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.4s;
    // transition: transform 0.4s, opacity 0.4s;

    &.expanded {
      // opacity: 1;
      transform: scaleY(1);
      transform-origin: top;
      transition: transform 0.4s;
      // transition: transform 0.4s, opacity 0.4s;

      & > td.v3-td {
        border-top: none;
      }
    }
  }
}
