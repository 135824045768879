@import 'constants.scss';

#batch-details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .header-container {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    position: relative;
    a.back {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $Silver;
      fill: $Silver;
      &:hover {
        color: $MCRBlack;
        fill: $MCRBlack;
      }
    }
    h1 {
      text-align: center;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  .add-access-codes {
    margin-left: 20px;
    a {
      text-decoration: none;
    }
  }

  .batch-details {
    margin: 0 20px 20px;  

    td.label {
      text-align: right;
      font-weight: 600;
      padding: 3px;
    }

    .shared-at-container {
      display: flex;
      align-items: center;

      .v3-button {
        height: 30px;
        // width: 50px;
        padding: 0 5px;
        // margin: 0;
        margin: 0 10px;
      }
    }
  }
  .batch-count-details {
    margin: 0px 20px 10px;  

    td.label {
      text-align: right;
      font-weight: 600;
      padding: 3px;
    }
  }

}