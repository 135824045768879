@import 'constants.scss';

@font-face {
  font-family: 'MarkForMC';
  src: local('MarkForMC'),
    url(./fonts/MarkOffc/MarkForMC.ttf) format('truetype');
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $AthensGray;
}

body {
  font-family: 'MarkForMC', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

button.reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
