@import 'constants.scss';

.toggle-wrapper {
  .tgl {
    display: none;

    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;
      &::selection {
        background: none;
      }
    }

    + .tgl-btn {
      outline: 0;
      display: block;
      width: 36px;
      height: 18px;
      position: relative;
      cursor: pointer;
      user-select: none;
      background: $GeyserGray;
      border-radius: 2em;
      padding: 2px;
      transition: all 0.4s ease;
      &:after,
      &:before {
        position: relative;
        top: 5%;
        display: block;
        content: '';
        width: 40%;
        height: 90%;
      }

      &:after {
        left: 5%;
        border-radius: 50%;
        background: #fff;
        transition: all 0.2s ease;
      }

      &:before {
        display: none;
      }
    }

    &:checked + .tgl-btn:after {
      left: 55%;
    }

    &:checked + .tgl-btn {
      background: $JungleGreen;
    }
  }
}
