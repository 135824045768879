@import 'constants.scss';

.users-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .users-table-header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "left center right";
    margin: 10px 20px;

    h1 {
      grid-area: center;
    }
    .dl-csv-button-container {
      grid-area: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      .download-csv-button {
        margin-right: 10px;
        font-size: 14px;
        height: 44px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0 20px;
        color: $White;
        box-shadow: $SmallBoxShadow;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.15s ease-in;
        justify-content: center;
        border-color: $JewelGreen;
        color: $JewelGreen;
        border: 1px solid;
        background-color: transparent;
        text-decoration: none;
        &:hover {
          background-color: $JewelGreen;
          color: $White;
          svg {
            fill: $White;
          }
        }
      }

      a.create-partner {
        text-decoration: none;
      }
    }
  }

  .table-controls {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: calc(100% - 8px);
    height: 30px;
    background-color: $AthensGray;
    display: flex;
    justify-content: center;
    box-shadow: 0 -5px 5px -1px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }

  .table-container {
    padding: 0 20px 30px;
    overflow-x: scroll;
    flex: 1;
    position: relative;
        
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #aaa;
      border-radius: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #000;
      border-radius: 5px;
    }

    .v3-table {
      .v3-td:first-child, .v3-th:first-child {
        padding-left: 10px;  
      }
      .v3-td:last-child, .v3-th:last-child {
        padding-right: 10px;  
      }

      .v3-thead {
        .v3-tr {
          white-space: nowrap;
          height: 40px;
          background: $AthensGray;
          position: sticky;
          top: 0;
          box-shadow: 0 5px 5px -1px rgba(0, 0, 0, 0.05);
          z-index: 1;
        }
      }

      .v3-tbody {
        .v3-tr {
          height: 50px;
          white-space: nowrap;

          &:first-child {
            .v3-td {
              border-top: none;
            }
          }

          &.inactive {
            .v3-td {
              opacity: 0.5;
            }
            .v3-td.edit-button-cell {
              opacity: 1;
            }
          }
        }

        .v3-td {
          &.is_active {
            color: $BrightRed;
            &.active {
              color: green;
            }
          }

          &.edit-button-cell {
            .v3-button {
              height: 30px;
              width: 50px;
              margin: 0 15px 0 0;
            }
          }

          .wrap {
            display: inline-block;
            white-space: wrap;
            min-width: 500px;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .no-associated-toe {
            color: $BrightRedA50;
          }
        }
      }

    }
  }
}