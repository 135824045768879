@import 'constants.scss';

div.v3-date-picker {
  position: relative;
  width: 300px;

  .react-datepicker-popper {
    z-index: 2;
  }

  input {
    padding: 8px 13px;
    border: 1px solid $GeyserGray;
    border-radius: 4px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    font-size: $FontSize;
    color: $FontColor;

    &:focus {
      border-radius: 4px 4px 0 0;
    }

    &::placeholder {
      color: $Silver;
    }
  }

  .icon-wrapper {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    fill: #435a70;
    display: flex;
    align-items: center;
    z-index: 1;
    pointer-events: none;

    &.selected {
      right: 25px;

      svg {
        fill: $JungleGreen;
      }
    }
  }

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: $FiordBlue;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '\00d7';
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: -1px;
  }

  .react-datepicker {
    border-radius: 0 0 4px 4px;
    font-size: 0.75rem;
    box-shadow: $SmallBoxShadow;
    border-color: $GeyserGray;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      background-color: $White;
      border-bottom: none;
      box-shadow: inset 0 5px 9px -7px rgba(14, 36, 53, 0.15);
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $FiordBlue;
      border: 1px solid transparent;
      padding: 0;
      margin: 1px 4px;
      text-transform: uppercase;
    }

    .react-datepicker__month {
      margin: 0 18.7px 15px;
    }

    .react-datepicker__day-name {
      font-weight: bold;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range {
      color: $White;
    }

    .react-datepicker__day--outside-month {
      color: $Silver;
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today {
      background-color: $RoyalBlueA05;
      // color: $White;
      border-radius: 0.3rem;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range {
      background-color: $RoyalBlue;
      font-weight: bold;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover {
      color: $NileBlue;
      font-weight: bold;
      background-color: transparent;
      border: 1px solid $GeyserGray;
      box-shadow: $SmallBoxShadow;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: $NileBlue;
      font-weight: 600;
      width: 95%;
      margin: 0 auto;
      padding: 8px 0;
      background: $RoyalBlueA05;
    }

    .react-datepicker__navigation {
      border: solid $NileBlue;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      top: 21px;
      width: 5px;
      height: 5px;
    }

    .react-datepicker__navigation--previous {
      transform: rotate(135deg);
      left: 20px;
    }

    .react-datepicker__navigation--next {
      transform: rotate(-45deg);
      right: 20px;
    }
  }
}
