#add-associated-toe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .search-form {
    display: flex;
    align-items: center;

    .v3-text-input input {
      min-width: 300px;
    }

    button.v3-button {
      margin-left: 10px;
      font-weight: 600;
    }
  }
}