@import 'constants.scss';

#user-details-container {
  margin: 0 20px;

  .header-container {
    display: flex;
    justify-content: center;
    position: relative;
    a.back {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $Silver;
      fill: $Silver;
      &:hover {
        color: $MCRBlack;
        fill: $MCRBlack;
      }
    }
    h1 {
      text-align: center;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  .user-details {
    margin: 20px;  

    td.label {
      text-align: right;
      font-weight: 600;
      padding: 3px;
    }
  }

}