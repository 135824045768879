.create-batch-form {
  display: block;
  max-width: 500px;
  margin: 20px;

  fieldset {
    border: 0;
    margin: 0 0 30px 0;
    padding: 0;

    legend {
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 5px;
    }

    input:hover {
      cursor: pointer;
    }

    label {
      padding-right: 5px;
      margin-right: 5px;
    }
  }

}