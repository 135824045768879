@import 'constants.scss';

#main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: $TextGray;
  position: relative;
  overflow-x: auto;
}
