@import 'constants.scss';

#partner-details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  
  .header-container {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    position: relative;
    a.back {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $Silver;
      fill: $Silver;
      &:hover {
        color: $MCRBlack;
        fill: $MCRBlack;
      }
    }
    h1 {
      text-align: center;
    }
    svg {
      transform: rotate(180deg);
    }
  }

  .access-code-batches-header-container {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    

    a.create-batch {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: $JewelGreen;
      &:hover {
        text-decoration: underline;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  h2 {
    margin-left: 20px;
  }

  .partner-details {
    margin: 20px;  

    td.label {
      text-align: right;
      font-weight: 600;
      padding: 3px;
    }
  }

  .reports-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin: 0 20px;
  }

}